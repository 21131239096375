/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector } from 'react-redux';

import { useServiceSelector } from 'src/hooks/useServiceSelector';
import { getUsers, getInvitedUsers } from '../../state/selectors';
import { useFetchUsers } from '../../state/hooks';
import UsersList from './UsersList';
import InvitedUsersList from './InvitedUsersList';

export const Users = () => {
  // const { data } = useServiceSelector(getUsers);
  const usersData = useSelector(getUsers);
  const invitedUsersData = useSelector(getInvitedUsers);

  useFetchUsers();

  return (
    <>
      {usersData && usersData.length > 0 && <UsersList data={usersData} />}
      {invitedUsersData && invitedUsersData.length > 0 && <InvitedUsersList data={invitedUsersData} />}
    </>
  );
};
