/* eslint-disable react/jsx-filename-extension */
import clsx from 'clsx';
import { useFormik, useState } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { makeStyles, FormHelperText, Typography, Grid, FormControl, TextField, FormControlLabel, Radio, Button, RadioGroup } from '@material-ui/core';

import Alert from 'src/components/alert';
import PhoneInput from 'src/components/phoneInput';

import { useUsersActions } from '../../state/hooks';
import { USER_ROLES } from '../../state/types';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 14,
    },
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 30,
    // margin: theme.spacing(0, -1),
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        width: '100%',
        maxWidth: 255,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  label: {
    opacity: 0.7,
  },
  error: {
    color: '#f44336',
  },
}));

const СreateUser = ({ onChangeType, countries, setCountries }) => {
  const classes = useStyles();

  const { createUser } = useUsersActions();

  const formik = useFormik({
    initialValues: {
      countryCodeId: '',
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      role: '',
    },
    validationSchema: yup.object().shape({
      countryCodeId: yup.string(),
      email: yup
        .string()
        .email()
        .required('Email is required'),
      phone: yup
        .string()
        .when(['countryCodeId'], (countryCodeId, schema) => {
          if (countryCodeId == '' || countries.length < 1) {
            return schema;
          }

          const country = countries.find(countryItem => countryItem.country_code === countryCodeId);

          if (country?.reg_pattern) {
            const pattern = country.reg_pattern.replace('/^', '^').replace('$/', '$');

            return schema.matches(new RegExp(pattern), 'Incorrect phone number.');
          }

          return schema;
        })
        .required('Phone is required.'),
      firstName: yup
        .string()
        .min(2, 'Min 2 symbols')
        .required('First name is required'),
      lastName: yup
        .string()
        .min(2, 'Min 2 symbols')
        .required('Last name is required'),
      password: yup
        .string()
        .min(8, 'Password must be contain at least 8 characters')
        .matches(/[0-9]/, 'Password requires at least one digit')
        .matches(/[a-z]/, 'Password requires at least one lowercase letter')
        .matches(/[A-Z]/, 'Password requires at least one uppercase letter')
        .matches(/[^\w]/, 'Password requires at least one special symbol')
        .required('Password is required'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Password and confirm password should match')
        .required('Confirm password is required'),
      role: yup
        .string()
        .required('Role is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      createUser({ ...values });
      resetForm();
    },
  });

  const handleChangePhone = (fieldName, value, countryCodeId) => {
    formik.setValues({ ...formik.values, phone: value, countryCodeId }, false);
  };

  const handleGetCountriesList = (data) => {
    if (data.length < 0) {
      return true;
    }

    setCountries(data);

    return true;
  };

  const handleCancel = () => {
    onChangeType(false);
  };

  return (
    <>
      <Typography variant="h6" className={classes.title} paragraph>
        Create User
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <FormControl margin="normal" fullWidth>
            <TextField
              id="email"
              variant="outlined"
              label="Email"
              size="small"
              color="secondary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email && formik.touched.email}
            />

            {
              formik.errors.email && formik.touched.email && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.email}</Alert>
                </FormHelperText>
              )
            }
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              id="firstName"
              variant="outlined"
              label="First Name"
              size="small"
              color="secondary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={formik.errors.firstName && formik.touched.firstName}
            />

            {
              formik.errors.firstName && formik.touched.firstName && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.firstName}</Alert>
                </FormHelperText>
              )
            }
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              id="lastName"
              variant="outlined"
              label="Last Name"
              size="small"
              color="secondary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={formik.errors.lastName && formik.touched.lastName}
            />

            {
              formik.errors.lastName && formik.touched.lastName && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.lastName}</Alert>
                </FormHelperText>
              )
            }
          </FormControl>
        </Grid>

        <Grid item xs={12} md={8}>
          <FormControl margin="normal" fullWidth>
            <PhoneInput
              user="true"
              country
              name="phone"
              size="small"
              value={formik.values.phone}
              countriesList={handleGetCountriesList}
              onChange={handleChangePhone}
              error={formik.errors.phone && formik.touched.phone}
            />

            {
              formik.errors.phone && formik.touched.phone && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.phone}</Alert>
                </FormHelperText>
              )
            }
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              id="password"
              variant="outlined"
              label="Password"
              size="small"
              color="secondary"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password && formik.touched.password}
            />

            {
              formik.errors.password && formik.touched.password && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.password}</Alert>
                </FormHelperText>
              )
            }
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              id="confirmPassword"
              variant="outlined"
              label="Confirm password"
              size="small"
              color="secondary"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={formik.errors.confirmPassword && formik.touched.confirmPassword}
            />

            {
              formik.errors.confirmPassword && formik.touched.confirmPassword && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.confirmPassword}</Alert>
                </FormHelperText>
              )
            }
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl>
            <Typography gutterBottom className={classes.label}>
              Select role
            </Typography>

            <RadioGroup
              row
              aria-label="role"
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
            >
              <FormControlLabel
                className={clsx({ [classes.error]: formik.errors.role && formik.touched.role })}
                value={USER_ROLES.admin}
                control={
                  <Radio
                    className={clsx({ [classes.error]: formik.errors.role && formik.touched.role })}
                    color="secondary"
                  />
                }
                label="Admin"
              />

              <FormControlLabel
                className={clsx({ [classes.error]: formik.errors.role && formik.touched.role })}
                value={USER_ROLES.manager}
                control={
                  <Radio
                    className={clsx({ [classes.error]: formik.errors.role && formik.touched.role })}
                    color="secondary"
                  />
                }
                label="Manager"
              />

              <FormControlLabel
                className={clsx({ [classes.error]: formik.errors.role && formik.touched.role })}
                value={USER_ROLES.viewer}
                control={
                  <Radio
                    className={clsx({ [classes.error]: formik.errors.role && formik.touched.role })}
                    color="secondary"
                  />
                }
                label="Viewer"
              />
            </RadioGroup>

            {
              formik.errors.role && formik.touched.role && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors.role}</Alert>
                </FormHelperText>
              )
            }
          </FormControl>
        </Grid>
      </Grid>

      <div className={classes.buttonWrapper}>
        <Button size="large" variant="outlined" color="primary" disableElevation onClick={handleCancel}>
          Cancel
        </Button>

        <Button size="large" variant="contained" color="primary" disableElevation onClick={formik.handleSubmit}>
          Save
        </Button>
      </div>
    </>
  );
};

export default СreateUser;
