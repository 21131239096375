import React from 'react';
import { useSelector } from 'react-redux';

import { useUsersActions, useUsersDetailsData } from 'src/features/users/state/hooks';
import { getMe } from 'src/features/users/state/selectors';
import { DetailsFormAvatar } from './DetailsFormAvatar';

export const DetailsAvatar = () => {
  const data = useSelector(getMe);
  const { isDetailsView } = useUsersDetailsData();
  const { updateAvatar, deleteAvatar } = useUsersActions();

  return (
    data && <DetailsFormAvatar data={data} isView={isDetailsView} onUpdate={updateAvatar} onDelete={deleteAvatar} />
  );
};
