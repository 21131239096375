import { useMemo, useState } from 'react';

const createInputElement = ({ multiple, accept }) => {
  const inputElement = document.createElement('input');
  inputElement.type = 'file';
  inputElement.accept = accept;
  inputElement.multiple = multiple;

  return inputElement;
};

export const useFileInput = () => {
  const [files, setFiles] = useState(null);
  let userCallback = () => {};

  const handleFileChosen = async file => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onload = () => resolve({ name: file.name, size: file.size, source: fileReader.result, file });
      fileReader.onerror = reject;

      fileReader.readAsDataURL(file);
    });
  };

  const handleFileChange = async ({ target }) => {
    const results = await Promise.all(
      [...target.files].map(async file => {
        const fileContents = await handleFileChosen(file);

        return fileContents;
      }),
    );

    target.removeEventListener('change', handleFileChange);
    target.remove();

    if (target.multiple) {
      setFiles(results);

      return userCallback(results);
    }

    setFiles(results[0]);

    return userCallback(results[0]);
  };

  const handleFormDataFileChange = async ({ target }) => {
    let result = null;

    await Promise.all(
      [...target.files].map(async file => {
        result = file;

        return;
      }),
    );

    target.removeEventListener('change', handleFormDataFileChange);
    target.remove();

    setFiles(result);

    return userCallback(result);
  };

  const uploadFile = ({ accept = '', multiple = false, formData = false }, cb) => {
    if (typeof cb === 'function') {
      userCallback = cb;
    }

    multiple = (!formData && multiple) ? true : false;

    const inputElement = createInputElement({ multiple, accept });

    inputElement.addEventListener('change', formData ? handleFormDataFileChange : handleFileChange);
    inputElement.click();
  };

  return useMemo(() => [files, uploadFile], [files]);
};
