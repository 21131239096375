/* eslint-disable react/jsx-filename-extension */
import React, { useMemo } from 'react';
import { makeStyles, TextField, Typography, Select, MenuItem } from '@material-ui/core';
import { MTableToolbar } from '@material-table/core';

import Paper from 'src/components/paper';
import MaterialTable from 'src/components/materialTable2';
import { useUsersActions } from '../../state/hooks';
import { USER_ROLES } from '../../state/types';

const useStyles = makeStyles(theme => ({
  label: {
    opacity: 0.7,
  },
  toolbar: {
    '& .MuiToolbar-root': {
      padding: theme.spacing(2, 0, 3),
    },
  },
  tableTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  tableTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  input: {
    width: 80,
    margin: '1px 10px',
  },
}));

const InvitedUsersList = ({ data }) => {
  const classes = useStyles();

  const { removeInvitedUser, updateInvitedUser } = useUsersActions();

  const columns = useMemo(
    () => [
      { title: 'Id', field: 'id', hidden: true },
      {
        title: 'User',
        field: 'user',
        render: rowData => (
          <Typography variant="body2" color="primary">
            {rowData.username || rowData.email}
          </Typography>
        ),
      },
      {
        title: 'Role',
        field: 'role',
        render: ({ role }) => (
          <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
            {role.value}
          </Typography>
        ),
        editComponent: ({ value, onChange }) => {
          Object.entries(USER_ROLES).map((entry) => (
            console.log(entry)
          ));
          const defaultValue = value?.id || value || '0';
          return (
            <Select style={{ textTransform: 'capitalize' }}
              value={defaultValue}
              onChange={(selectedOption) => onChange(selectedOption.target.value)}>
              {
                Object.entries(USER_ROLES).map((entry) => (
                  <MenuItem
                    style={{ textTransform: 'capitalize' }}
                    key={entry[0]}
                    id={entry[0]}
                    value={entry[1]}
                  >
                    {entry[0]}
                  </MenuItem>
                ))
              }
            </Select>
          )
        }
      },
    ],
    [],
  );
  const editableData = useMemo(() => JSON.parse(JSON.stringify(data)), [data]);

  const handleRowUpdate = (newData, oldData) => {
    const { id, user, role } = newData;

    let updateProps = {
      id,
      email: user,
      role,
    };

    return new Promise(resolve => {
      resolve(updateInvitedUser(updateProps));
    });
  };

  const handleRowDelete = user => {
    const { id } = user;

    return new Promise(resolve => {
      resolve(removeInvitedUser(id));
    });
  };

  return (
    <Paper>
      {editableData && columns &&
        <MaterialTable
          title={
            <div className={classes.tableTitleWrapper}>
              <Typography variant="h6">
                Invites
              </Typography>

              <div className={classes.tableTitle}>
                <Typography className={classes.label}>Show</Typography>

                <TextField type="number" variant="outlined" size="small" className={classes.input} defaultValue={10} />

                <Typography className={classes.label}>entries</Typography>
              </div>

            </div>
          }
          components={{
            Toolbar: props => (
              <div className={classes.toolbar}>
                <MTableToolbar {...props} className={classes.toolbar} searchFieldVariant="outlined" />
              </div>
            ),
          }}
          columns={columns}
          data={editableData}
          options={{
            toolbar: true,
            search: true,
            showTitle: true,
          }}
          editable={{
            onRowUpdate: handleRowUpdate,

            onRowDelete: handleRowDelete,
          }}
          // actions={[
          //   {
          //     icon: tableIcons.Delete,
          //     tooltip: 'Delete User',
          //     onClick: (event, rowData) => {
          //       // Do save operation
          //     },
          //   },
          // ]}
          localization={{
            header: {
              actions: '',
            },
          }}
        />
      }
    </Paper>
  );
};

export default InvitedUsersList;
