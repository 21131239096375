import React, { useMemo } from 'react';
import { Avatar, IconButton, makeStyles } from '@material-ui/core';

import { useFileInput } from 'src/hooks/useFileInput';

import CloseIcon from 'src/assets/icons/close.svg';
import DeleteIcon from 'src/assets/icons/delete.svg';
import EditIcon from 'src/assets/icons/edit.svg';

const useStyles = makeStyles(theme => ({
  avatarWrapper: {
    position: 'relative',
    display: 'flex',
  },
  avatar: {
    height: 120,
    width: 120,
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  avatarEdit: {
    position: 'absolute',
    width: 140,
    display: 'flex',
    justifyContent: 'space-between',
    bottom: -10,
    left: -10,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      right: 0,
      margin: 'auto',
    },
  },
  closeIcon: {
    '& > rect:first-child': {
      fill: '#F5D6CC',
      opacity: 1,
    },
  },
  editIcon: {
    '& > rect:first-child': {
      fill: '#dcf6ec',
      opacity: 1,
    },
  },
}));

export const DetailsFormAvatar = ({ data, isView, onUpdate, onDelete }) => {
  const classes = useStyles();

  const { image } = data;
  const [_, uploadAvatar] = useFileInput();

  const avatar = useMemo(() => {
    let src = '';
    // let src =
    //   'https://s3-alpha-sig.figma.com/img/3639/1226/a506d4da9d35f00a25557f052d098a9d?Expires=1604275200&Signature=BWyIjADsGgGf5aQBTy-T4MyGpxetwAZMaH-034ErCTVxry2bQCi4CIsiHOCXVSwF2buklqUYhim7vOPBNdxh1jcbrR-jw7R3rZ0vZf6Fi-fIIYxMDqo91syQy-ZI0IlKenAepfCg34QiEbyX7qmyqQ8n-Bivjcd~nx77~r8XSlVScYaKQQTiot7Ij~di7Heki2z2HZK7PZ1eOov42p7uhlma5IVL8Lv2QWEOAmejZIwZs1QiyRwzgivVIFdT-kMqw2tCamoSlZ5XFZzva3Anu-Dc4289lS7WDE1sii5Im4K45b8ThK8gAH3LBma~v63nMD42tEqDE3qQGDp5Na2FDw__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA';

    if (image) {
      const type = image.charAt(0) === 'i' ? 'png' : 'jpg';

      src = `data:image/${type};base64,${image}`;
    }

    return src;
  }, [image]);

  const handleChangeAvatar = () => {
    uploadAvatar({ accept: '.png, .jpg' }, ({ source }) => {
      const src = source.split(',').at(1);

      onUpdate(src);
    });
  };

  return (
    <div className={classes.avatarWrapper}>
      <Avatar className={classes.avatar} src={avatar} alt="Remy Sharp" />

      <div className={classes.avatarEdit}>
        {!isView && (
          <>
            <IconButton onClick={handleChangeAvatar}>
              <EditIcon className={classes.editIcon} />
            </IconButton>

            <IconButton onClick={onDelete}>
              <DeleteIcon className={classes.closeIcon} />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};
