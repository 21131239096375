/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik, getIn } from 'formik';
import * as yup from 'yup';
import {
  makeStyles,
  Typography,
  Button,
  IconButton,
  Grid,
  FormControl,
  TextField,
  Divider,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Checkbox from 'src/components/checkbox';
import Paper from 'src/components/paper';
import TooltipedText from 'src/components/tooltipedText';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { getConfiguration, getLoading } from 'src/features/advanceSettings/state/selectors';
import { useAdvancedActions, useFetchAdvanced } from 'src/features/advanceSettings/state/hooks'

const useStyles = makeStyles(theme => ({
  loader: {},
  root: {},
  header: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 14,
    },
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 30,
    // margin: theme.spacing(0, -1),
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        width: '100%',
        maxWidth: 255,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  buttonSecondary: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    height: 40,
    padding: theme.spacing(1, 2)
  },
  label: {
    opacity: 0.7,
  },
  buttonContainer: {
    display: 'flex',
  },
  buttonWrapper2: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  wrapFull: {
    width: '100%',
  },
  flexWrap: {
    display: 'flex',
  },
  flexWrapItem: {
    width: '25%',
  }
}));

export default function () {
  const classes = useStyles();

  const data = useSelector(getConfiguration);

  const isLoading = useSelector(getLoading);

  const { updateConfiguration } = useAdvancedActions();

  useFetchAdvanced();

  const formik = useFormik({
    initialValues: {
      enable_call_recording: data.enable_call_recording,
      calls_paused: data.calls_paused,
      ivr_announce: data.ivr_announce,
      caller_id_for_reps: data.caller_id_for_reps,
      caller_id_for_leads: data.caller_id_for_leads,
      tags: data.tags,
      blackout_days: data.blackout_days,
      disable_parking_arrived_on_blackout_day: data.disable_parking_arrived_on_blackout_day,
      email_reports: data.email_reports,
      data_retention_period: data.data_retention_period,
    },
    validationSchema: yup.object().shape({
      email_reports: yup.array().of(
        yup.object().shape({
          email: yup.string().email().required(),
          daily: yup.boolean().required(),
          weekly: yup.boolean().required(),
          monthly: yup.boolean().required(),
          all: yup.boolean(),
        })
      ),
    }),
    validateOnBlur: true,
    onSubmit: values => {
      updateConfiguration(values)
    },
  });

  const handleSetTouched = (index) => {
    const email_reports = [];

    email_reports[index] = { frequency: true };

    formik.setTouched({ email_reports });
  };

  const handleCancelChanges = () => {
    formik.setValues(data, true);
    formik.setTouched({ email_reports: [] });

    data.email_reports.forEach((emailReport, index) => {
      if (emailReport.daily == true && emailReport.weekly == true && emailReport.monthly == true) {
        formik.setFieldValue(`email_reports[${index}].all`, true, false);
      } else {
        formik.setFieldValue(`email_reports[${index}].all`, false, false);
      }
    });
  };

  const handleAddReporterEmail = () => {
    const reportersEmails = JSON.parse(JSON.stringify(formik.values.email_reports));

    const newReporterEmail = {
      email: '',
      frequency: '0',
      daily: true,
      weekly: true,
      monthly: true,
      all: true,
    };

    formik.setFieldValue('email_reports', [
      ...reportersEmails,
      newReporterEmail,
    ], false);
  };

  const handleDeleteReporterEmail = (index) => {
    const reportersEmails = JSON.parse(JSON.stringify(formik.values.email_reports));

    reportersEmails.splice(index, 1);

    formik.setFieldValue('email_reports', [
      ...reportersEmails,
    ], false);

    handleSetTouched(0);
  };

  const handleEmailReportsFrequency = (event, value, index) => {
    const { target } = event;

    const [firstPart, secondPart] = target.name.split('.');

    formik.setFieldValue(target.name, value, false);

    switch (secondPart) {
      case 'all':
        formik.setFieldValue(`${firstPart}.daily`, value, false);
        formik.setFieldValue(`${firstPart}.weekly`, value, false);
        formik.setFieldValue(`${firstPart}.monthly`, value, false);

        break;
      case 'daily':
        if (value === false) {
          formik.setFieldValue(`${firstPart}.all`, value);
        } else if (
          formik.values.email_reports[index].weekly === true &&
          formik.values.email_reports[index].monthly === true
        ) {
          formik.setFieldValue(`${firstPart}.all`, value, false);
        }

        break;
      case 'weekly':
        if (value === false) {
          formik.setFieldValue(`${firstPart}.all`, value, false);
        } else if (
          formik.values.email_reports[index].daily === true &&
          formik.values.email_reports[index].monthly === true
        ) {
          formik.setFieldValue(`${firstPart}.all`, value, false);
        }

        break;
      case 'monthly':
        if (value === false) {
          formik.setFieldValue(`${firstPart}.all`, value, false);
        } else if (
          formik.values.email_reports[index].daily === true &&
          formik.values.email_reports[index].weekly === true
        ) {
          formik.setFieldValue(`${firstPart}.all`, value, false);
        }

        break;
      default:
        break;
    }

    handleSetTouched(index);
  };

  useEffect(() => {
    formik.setValues(data, false);
    formik.setTouched({ email_reports: [] });

    data.email_reports.forEach((emailReport, index) => {
      if (emailReport.daily == true && emailReport.weekly == true && emailReport.monthly == true) {
        formik.setFieldValue(`email_reports[${index}].all`, true, false);
      } else {
        formik.setFieldValue(`email_reports[${index}].all`, false, false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <LoadingContainer classNames={classes.loader} type="group" loading={isLoading}>
      <Paper className={classes.root}>
        <TooltipedText tooltipText="Update your report notification and/or add new recipients">
          <Typography variant="h6">Email Report Notifications</Typography>
        </TooltipedText>

        {
          formik.values.email_reports.length > 0 && (formik.values.email_reports.map((emailReport, index) => (
            <Grid key={index} container style={{ alignItems: 'center' }}>
              <Grid item xs={12} md={4}>
                <FormControl margin="normal" fullWidth>
                  <TextField
                    id={`email_reports[${index}].email`}
                    label={`Recipient Email Address ${index + 1}`}
                    name={`email_reports[${index}].email`}
                    value={emailReport.email}
                    type="text"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={!!getIn(formik.errors, `email_reports[${index}].email`)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4} md={1}>
                <IconButton
                  label="Delete reporter email"
                  onClick={() => { handleDeleteReporterEmail(index) }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>

              <Grid item xs={12} md={1} />

              <Grid item xs={12} md={6}>
                <FormControl className={classes.wrapFull} margin="normal">
                  <Typography gutterBottom className={classes.label}>
                    Frequency
                  </Typography>

                  <div className={classes.flexWrap}>
                    <div className={classes.flexWrapItem}>
                      <Checkbox
                        label="Daily"
                        id={`email_reports[${index}].daily`}
                        name={`email_reports[${index}].daily`}
                        checked={emailReport.daily}
                        onChange={(event, value) => handleEmailReportsFrequency(event, value, index)}
                      />
                    </div>

                    <div className={classes.flexWrapItem}>
                      <Checkbox
                        label="Weekly"
                        id={`email_reports[${index}].weekly`}
                        name={`email_reports[${index}].weekly`}
                        checked={emailReport.weekly}
                        onChange={(event, value) => handleEmailReportsFrequency(event, value, index)}
                      />
                    </div>

                    <div className={classes.flexWrapItem}>
                      <Checkbox
                        label="Monthly"
                        id={`email_reports[${index}].monthly`}
                        name={`email_reports[${index}].monthly`}
                        checked={emailReport.monthly}
                        onChange={(event, value) => handleEmailReportsFrequency(event, value, index)}
                      />
                    </div>

                    <div className={classes.flexWrapItem}>
                      <Checkbox
                        label="All"
                        id={`email_reports[${index}].all`}
                        name={`email_reports[${index}].all`}
                        checked={emailReport.all}
                        onChange={(event, value) => handleEmailReportsFrequency(event, value, index)}
                      />
                    </div>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          )))
        }

        <Divider className={classes.divider} />

        <div className={classes.buttonContainer}>
          <div className={classes.buttonWrapper2}>
            <Button
              size="medium"
              color="primary"
              onClick={handleAddReporterEmail}
              disableElevation
              className={classes.buttonSecondary}
            >
              <span style={{ fontSize: 20, marginRight: 8, marginBottom: 4 }}>+</span> Add Another Recipient
            </Button>
          </div>

          <div className={classes.buttonWrapper}>
            <Button
              size="large"
              color="primary"
              onClick={handleCancelChanges}
              disableElevation
              disabled={!(formik.touched.email_reports?.length > 0)}
            >
              Cancel
            </Button>

            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={formik.submitForm}
              disableElevation
              disabled={!formik.isValid || !(formik.touched.email_reports?.length > 0)}
            >
              Save
            </Button>
          </div>
        </div>
      </Paper>
    </LoadingContainer>
  );
}
