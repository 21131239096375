import { useSelector } from 'react-redux'

export const useServiceSelector = (selector) => {
  const {
    data = null,
    isLoading = true,
    error = null,
  } = useSelector(selector) || {}

  return {
    data,
    isLoading,
    error,
  }
}