/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { makeStyles, Typography, Button } from '@material-ui/core';

import Paper from 'src/components/paper';
import CreateUser from 'src/features/users/components/newUser/СreateUser';
import InviteUser from './InviteUser';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(2, 4),
  },
  header: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 14,
    },
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 30,
    // margin: theme.spacing(0, -1),
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        width: '100%',
        maxWidth: 255,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
}));

export function NewUser() {
  const classes = useStyles();
  const [type, setType] = useState(false);
  const [countries, setCountries] = useState([]);

  const HandleCreate = () => {
    setType('create');
  };

  const HandleInvite = () => {
    setType('invite');
  };

  const handleChangeType = (typeValue) => {
    setType(typeValue);
  };

  return (
    <>
      <Paper className={classes.root}>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.title}>
            Users
          </Typography>

          <div className={classes.buttonWrapper}>
            <Button size="large" variant="contained" color="primary" disableElevation onClick={HandleCreate}>
              Create
            </Button>

            <Button size="large" variant="contained" color="primary" disableElevation onClick={HandleInvite}>
              Invite
            </Button>
          </div>
        </div>
      </Paper>
      {
        type === 'create' && (
          <Paper>
            <CreateUser onChangeType={handleChangeType} countries={countries} setCountries={setCountries} />
          </Paper>
        )
      }
      {
        type === 'invite' && (
          <Paper>
            <InviteUser onChangeType={handleChangeType} />
          </Paper>
        )
      }
    </>
  );
}
