/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import { DetailsPanel } from './DetailsPanel';

import { getLoading } from '../../state/selectors';
import { useFetchMe } from '../../state/hooks';

const useStyles = makeStyles(() => ({
  loader: {},
}));

export const Details = () => {
  const classes = useStyles();

  const isLoading = useSelector(getLoading);

  useFetchMe();

  return (
    <LoadingContainer classNames={classes.loader} type="group" loading={isLoading}>
      <DetailsPanel />
    </LoadingContainer>
  );
};
