import React from 'react';
import { useSelector } from 'react-redux';

import { getMe } from 'src/features/users/state/selectors';
import { useUsersDetailsData } from 'src/features/users/state/hooks';
import { DetailsFormEdit } from './DetailsFormEdit';

export const DetailsEdit = () => {
  const data = useSelector(getMe);
  const { toggleDetailsMode } = useUsersDetailsData();

  return data && <DetailsFormEdit data={data} toggleMode={toggleDetailsMode} />;
};
