import React from 'react';
import {
  makeStyles,
  Typography,
  Button,
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Alert from 'src/components/alert';
import { USER_ROLES } from '../../state/types';
import { useUsersActions } from '../../state/hooks';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 14,
    },
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 30,
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        width: '100%',
        maxWidth: 255,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  label: {
    opacity: 0.7,
  },
  buttonContainer: {
    display: 'flex',
  },
  buttonWrapper2: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  buttonSecondary: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    height: 40,
    padding: theme.spacing(1, 2)
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  error: {
    color: '#f44336',
  },
}));

const inviteUserSchema = yup.object().shape({
  invitedList: yup.array().of(
    yup.object().shape({
      email: yup.string().email('Must be a valid email').required('Email is required'),
      role: yup.string().required('Role is required'),
    }),
  ),
});

const InviteUser = ({ onChangeType }) => {
  const classes = useStyles();
  const { inviteUser } = useUsersActions();

  const formik = useFormik({
    initialValues: {
      invitedList: [{ email: '', role: '' }],
    },
    validationSchema: inviteUserSchema,
    onSubmit: (values, { resetForm }) => {
      values.invitedList.forEach(invite => {
        inviteUser(invite);
      });

      resetForm();
    },
  });

  const handleAddInvited = () => {
    formik.setFieldValue('invitedList', [...formik.values.invitedList, { email: '', role: '' }]);
  };

  const handleCancel = () => {
    onChangeType(false);
  };

  return (
    <>
      <Typography variant="h6" className={classes.title} gutterBottom>
        Invite Users
      </Typography>

      {formik.values.invitedList.map((user, index) => (
        <Grid key={index} container style={{ alignItems: 'center' }}>
          <Grid item xs={12} md={4}>
            <FormControl margin="normal" fullWidth>
              <TextField
                name={`invitedList.${index}.email`}
                variant="outlined"
                label={`Invite Email ${index + 1}`}
                size="small"
                color="secondary"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={user.email}
                error={formik.errors?.invitedList?.at(index)?.email && formik.touched?.invitedList?.at(index)?.email}
              />

              {formik.errors?.invitedList?.at(index)?.email && formik.touched?.invitedList?.at(index)?.email && (
                <FormHelperText component="div">
                  <Alert severity="error">{formik.errors?.invitedList?.at(index)?.email}</Alert>
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={4}>
            <FormControl margin="normal">
              <Typography gutterBottom className={classes.label}>
                Select role
              </Typography>
              <RadioGroup
                name={`invitedList.${index}.role`}
                row
                aria-label="role"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={user.role}
              >
                <FormControlLabel
                  className={clsx({
                    [classes.error]:
                      formik.errors?.invitedList?.at(index)?.role && formik.touched?.invitedList?.at(index)?.role,
                  })}
                  value={USER_ROLES.admin}
                  control={
                    <Radio
                      className={clsx({
                        [classes.error]:
                          formik.errors?.invitedList?.at(index)?.role && formik.touched?.invitedList?.at(index)?.role,
                      })}
                      color="secondary"
                    />
                  }
                  label="Admin"
                />
                <FormControlLabel
                  className={clsx({
                    [classes.error]:
                      formik.errors?.invitedList?.at(index)?.role && formik.touched?.invitedList?.at(index)?.role,
                  })}
                  value={USER_ROLES.manager}
                  control={
                    <Radio
                      className={clsx({
                        [classes.error]:
                          formik.errors?.invitedList?.at(index)?.role && formik.touched?.invitedList?.at(index)?.role,
                      })}
                      color="secondary"
                    />
                  }
                  label="Manager"
                />
                <FormControlLabel
                  className={clsx({
                    [classes.error]:
                      formik.errors?.invitedList?.at(index)?.role && formik.touched?.invitedList?.at(index)?.role,
                  })}
                  value={USER_ROLES.viewer}
                  control={
                    <Radio
                      className={clsx({
                        [classes.error]:
                          formik.errors?.invitedList?.at(index)?.role && formik.touched?.invitedList?.at(index)?.role,
                      })}
                      color="secondary"
                    />
                  }
                  label="Viewer"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      ))}

      <Divider className={classes.divider} />

      <div className={classes.buttonContainer}>
        <div className={classes.buttonWrapper2}>
          <Button
            size="medium"
            color="primary"
            onClick={handleAddInvited}
            disableElevation
            className={classes.buttonSecondary}
          >
            <span style={{ fontSize: 20, marginRight: 8, marginBottom: 4 }}>+</span> Add Another Invitation
          </Button>
          {/* <Button size="large" variant="contained" color="primary" disableElevation onClick={handleAddInvited}>
            Add Another Invitation
          </Button> */}
        </div>
        <div className={classes.buttonWrapper}>
          <Button size="large" variant="outlined" color="primary" disableElevation onClick={handleCancel}>
            Cancel
          </Button>
          <Button size="large" variant="contained" color="primary" disableElevation onClick={formik.handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
export default InviteUser;
