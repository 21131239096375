import React from 'react';
import { Grid, FormControl, FormHelperText, TextField, makeStyles, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Alert from 'src/components/alert';
import { useUsersActions } from 'src/features/users/state/hooks';

const useStyles = makeStyles(theme => ({
  label: {
    opacity: 0.7,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    minHeight: 30,
    '& > *': {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& > *': {
        width: '100%',
        maxWidth: 255,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
}));

const updateUserSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, 'Min 2 symbols')
    .required('Name is required'),
  email: yup
    .string()
    .email()
    .required('Email is required'),
  phone: yup
    .string()
    .typeError('Must be a number')
    .required('Phone is required'),
  password: yup
    .string()
    .min(8, 'Password must be contain at least 8 characters')
    .matches(/[0-9]/, 'Password requires at least one digit')
    .matches(/[a-z]/, 'Password requires at least one lowercase letter')
    .matches(/[A-Z]/, 'Password requires at least one uppercase letter')
    .matches(/[^\w]/, 'Password requires at least one special symbol'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password and confirm password should match'),
});

export const DetailsFormEdit = ({ data, toggleMode }) => {
  const classes = useStyles();

  const { id, name, username, phone, email, role } = data;
  const { updateUser } = useUsersActions();

  const formik = useFormik({
    initialValues: {
      name,
      email,
      phone,
      password: '',
      confirmPassword: '',
    },
    validationSchema: updateUserSchema,
    onSubmit: values => {
      updateUser({ id, username, role: role.id, ...values });
      toggleMode();
    },
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <FormControl fullWidth>
            <TextField
              id="name"
              variant="outlined"
              label="Name"
              size="small"
              color="secondary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.errors.name && formik.touched.name}
            />

            {formik.errors.name && formik.touched.name && (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.name}</Alert>
              </FormHelperText>
            )}
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              id="email"
              variant="outlined"
              label="Email"
              size="small"
              color="secondary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email && formik.touched.email}
            />

            {formik.errors.email && formik.touched.email && (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.email}</Alert>
              </FormHelperText>
            )}
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              id="phone"
              variant="outlined"
              label="Phone"
              size="small"
              color="secondary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              error={formik.errors.phone && formik.touched.phone}
            />

            {formik.errors.phone && formik.touched.phone && (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.phone}</Alert>
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={5}>
          <FormControl fullWidth>
            <TextField
              id="password"
              variant="outlined"
              label="Password"
              size="small"
              color="secondary"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password && formik.touched.password}
            />

            {formik.errors.password && formik.touched.password && (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.password}</Alert>
              </FormHelperText>
            )}
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <TextField
              id="confirmPassword"
              variant="outlined"
              label="Confirm password"
              size="small"
              color="secondary"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              error={formik.errors.confirmPassword && formik.touched.confirmPassword}
            />

            {formik.errors.confirmPassword && formik.touched.confirmPassword && (
              <FormHelperText component="div">
                <Alert severity="error">{formik.errors.confirmPassword}</Alert>
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>

      <div className={classes.buttonWrapper}>
        <Button variant="outlined" size="large" color="primary" onClick={toggleMode}>
          Cancel
        </Button>

        <Button variant="contained" size="large" color="primary" onClick={formik.handleSubmit} disableElevation>
          Save
        </Button>
      </div>
    </>
  );
};
