/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { makeStyles, Typography, IconButton, Grid, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import Paper from 'src/components/paper';
import { useUsersDetailsData } from '../../state/hooks';
import { DetailsEdit } from './components/DetailsEdit/DetailsEdit';
import { DetailsView } from './components/DetailsView/DetailsView';
import { DetailsAvatar } from './components/DetailsAvatar/DetailsAvatar';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  iconButton: {
    position: 'relative',
    bottom: 20,
    left: 10,
  },
}));

export const DetailsPanel = () => {
  const classes = useStyles();

  const { isDetailsView, toggleDetailsMode } = useUsersDetailsData();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelEdit = () => {
    toggleDetailsMode();
    handleClose();
  };

  return (
    <Paper>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          My Details
        </Typography>

        <IconButton className={classes.iconButton} aria-haspopup="true" onClick={handleClick}>
          <MoreHorizIcon color="secondary" fontSize="large" />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handelEdit} id="Group 1">
            Edit Profile
          </MenuItem>
        </Menu>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          <DetailsAvatar />
        </Grid>

        <Grid container item xs={12} md={10}>
          {isDetailsView ? <DetailsView /> : <DetailsEdit />}
        </Grid>
      </Grid>
    </Paper>
  );
};
