/* eslint-disable react/jsx-filename-extension */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, TextField, Typography, Select, MenuItem } from '@material-ui/core';
import { MTableToolbar } from '@material-table/core';

import Paper from 'src/components/paper';
import MaterialTable from 'src/components/materialTable2';

import { getMe } from 'src/features/users/state/selectors';

import { useUsersActions } from '../../state/hooks';
import { USER_ROLES } from '../../state/types';

const useStyles = makeStyles(theme => ({
  label: {
    opacity: 0.7,
  },
  toolbar: {
    '& .MuiToolbar-root': {
      padding: theme.spacing(2, 0, 3),
    },
  },
  tableTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  tableTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  input: {
    width: 80,
    margin: '1px 10px',
  },
}));

const UsersList = ({ data }) => {

  const classes = useStyles();

  const { removeUser, updateUser } = useUsersActions();

  const me = useSelector(getMe);

  const columns = useMemo(
    () => [
      { title: 'Id', field: 'id', hidden: true },
      {
        title: 'Name',
        field: 'name',
        render: rowData => (
          <Typography variant="body2">
            {rowData.name}
          </Typography>
        ),
      },
      {
        title: 'Email',
        field: 'email',
        render: rowData => (
          <Typography variant="body2" color="primary">
            {rowData.email}
          </Typography>
        ),
      },
      {
        title: 'Role',
        field: 'role',
        render: ({ role }) => (
          <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
            {role.value}
          </Typography>
        ),
        editComponent: ({ value, onChange, rowData }) => {
          const defaultValue = value?.id || value || '0';
          return (
            <Select style={{ textTransform: 'capitalize' }}
              value={defaultValue}
              onChange={(selectedOption) => {
                return onChange(selectedOption.target.value)
              }}>
              {
                (((value.id === '0') || (value === 0)) && (rowData.id === me.id)) ?
                  <MenuItem
                    style={{ textTransform: 'capitalize' }}
                    key="admin"
                    id="admin"
                    value="0"
                  >
                    Admin
                  </MenuItem>
                  :
                  Object.entries(USER_ROLES).map((entry) => (
                    <MenuItem
                      style={{ textTransform: 'capitalize' }}
                      key={entry[0]}
                      id={entry[0]}
                      value={entry[1]}
                    >
                      {entry[0]}
                    </MenuItem>
                  ))
              }
            </Select>
          )
        },
        customSort: (a, b) => (a.role.id - b.role.id),
      },
      {
        title: 'Phone',
        field: 'phone',
        render: ({ phone }) => (
          <Typography variant="body2">
            {phone}
          </Typography>
        ),
      },
      {
        title: 'Password',
        field: 'password',
        render: () => (
          <Typography variant="body2">
            ********
          </Typography>
        ),
        validate: rowData => {
          if (!rowData.password) {
            return { isValid: true, helperText: '' };
          }
          if (rowData.password.length < 8) {
            return { isValid: false, helperText: 'Password must be contain at least 8 characters' };
          }
          if (!rowData.password.match(/[0-9]/)) {
            return { isValid: false, helperText: 'Password requires at least one digit' };
          }
          if (!rowData.password.match(/[a-z]/)) {
            return { isValid: false, helperText: 'Password requires at least one lowercase letter' };
          }
          if (!rowData.password.match(/[A-Z]/)) {
            return { isValid: false, helperText: 'Password requires at least one uppercase letter' };
          }
          if (!rowData.password.match(/[^\w]/)) {
            return { isValid: false, helperText: 'Password requires at least one special symbol' };
          }
          return { isValid: true, helperText: '' };
        },
      },
    ],
    [],
  );
  const editableData = useMemo(() => JSON.parse(JSON.stringify(data)), [data]);

  const handleRowUpdate = (newData, oldData) => {
    const { id, name, user, email, phone, role, password } = newData;

    let updateProps = {
      id,
      name,
      username: user,
      email,
      phone,
      role: role?.id || role,
      password: password || '',
      confirmPassword: password || '',
    };

    return new Promise(resolve => {
      resolve(updateUser(updateProps));
    });
  };

  const handleRowDelete = user => {
    const { id, status } = user;

    return new Promise(resolve => {
      resolve(removeUser(id));
    });
  };

  return (
    <Paper>
      {editableData && columns &&
        <MaterialTable
          title={
            <div className={classes.tableTitleWrapper}>
              <Typography variant="h6">
                Users List
              </Typography>

              <div className={classes.tableTitle}>
                <Typography className={classes.label}>Show</Typography>

                <TextField type="number" variant="outlined" size="small" className={classes.input} defaultValue={10} />

                <Typography className={classes.label}>entries</Typography>
              </div>

            </div>
          }
          components={{
            Toolbar: props => (
              <div className={classes.toolbar}>
                <MTableToolbar {...props} className={classes.toolbar} searchFieldVariant="outlined" />
              </div>
            ),
          }}
          columns={columns}
          data={editableData}
          options={{
            toolbar: true,
            search: true,
            showTitle: true,
          }}
          editable={{
            onRowUpdate: handleRowUpdate,

            onRowDelete: handleRowDelete,
          }}
          // actions={[
          //   {
          //     icon: tableIcons.Delete,
          //     tooltip: 'Delete User',
          //     onClick: (event, rowData) => {
          //       // Do save operation
          //     },
          //   },
          // ]}
          localization={{
            header: {
              actions: '',
            },
          }}
        />
      }
    </Paper>
  );
};

export default UsersList;
