import React from 'react';
import { useSelector } from 'react-redux';

import checkRole from 'src/helpers/aclHelper';

import SEO from 'src/components/seo';
import EmailReport from 'src/features/advanceSettings/emailReport';
import { Details, NewUser, Users } from 'src/features/users';

import { getMe } from 'src/features/users/state/selectors';

import { removeRedirectUrl } from 'src/api/_common';

export default function UsersPage() {
  const userData = useSelector(getMe);

  removeRedirectUrl();

  return (
    <>
      <SEO title="Settings | Users" />

      <Details />

      {
        (userData?.customer?.is_subaccount === false && checkRole(['admin', 'manager'])) && (
          <>
            <EmailReport />
          </>
        )
      }

      {checkRole('admin') && (
        <>
          <NewUser />

          <Users />
        </>
      )}

    </>
  );
}
