/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React, { Component, useState, useEffect } from 'react';
import { Grid, FormControl, makeStyles, Typography, Divider, Button } from '@material-ui/core';
import { phoneFormatter } from 'src/helpers/phoneFormatterHelper';

import { useFetchBillingPortal } from '../../../../state/hooks';

const useStyles = makeStyles(theme => ({
  dot: {
    display: 'block',
    width: 8,
    height: 8,
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    margin: '7px 2px',
  },
  label: {
    opacity: 0.7,
  },
  textTransformCapitalize: {
    textTransform: 'capitalize',
  },
}));

export const DetailsFormView = ({ data }) => {
  const { getBillingData } = useFetchBillingPortal();
  const [cbInstance, setCbInstance] = useState(null);

  class BillingComponent extends Component {
    componentDidMount() {
      let flag = false;

      document.querySelectorAll('script').forEach((el) => {
        if (el.src.indexOf('chargebee') >= 0) { flag = true; }
      });

      if (!flag) {
        const head = document.querySelector('head');
        const script = document.createElement('script');

        script.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
        head.appendChild(script);
      }

      if (!cbInstance) {
        this.createChargebeeInstance();
      }
    }

    createChargebeeInstance = () => {
      setTimeout(() => {
        if (typeof Chargebee == 'undefined') {
          this.createChargebeeInstance();

          return;
        }

        const cbInstanceInit = Chargebee.init({
          site: `${process.env.CHARGEBEE_SITE}`,
        });

        setCbInstance(cbInstanceInit);
      }, 500);
    }

    handleOpenBilling = () => {
      let billingData;
      let cbPortal;

      getBillingData().then((data) => {
        billingData = data.payload?.configuration;

        if (!billingData) {
          return;
        }

        cbInstance.setPortalSession(function () {
          return new Promise(resolve => {
            resolve(billingData);
          });
        });

        cbPortal = cbInstance.createChargebeePortal();
        cbPortal.open({
          close() {
            cbInstance.logout();
          }
        });
      });
    }

    render() {
      return (<>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={this.handleOpenBilling}
          disableElevation
        >
          Billing
        </Button>
      </>);
    }
  }

  const classes = useStyles();

  const { id, name, phone, email, role, customer } = data;

  return (
    <>
      <Grid item xs={12} md={4}>
        <FormControl margin="normal" fullWidth>
          <Typography gutterBottom className={classes.label}>
            Name
          </Typography>

          <Typography variant="button">{name}</Typography>
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <Typography gutterBottom className={classes.label}>
            Email
          </Typography>

          <Typography variant="button">{email}</Typography>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={3}>
        <FormControl margin="normal" fullWidth>
          <Typography gutterBottom className={classes.label}>
            Phone
          </Typography>

          <Typography variant="button">{phone && phoneFormatter(phone)}</Typography>
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <Typography gutterBottom className={classes.label}>
            Password
          </Typography>

          <div style={{ display: 'flex' }}>
            {[...Array(8).keys()].map(ele => (
              <span key={ele} className={classes.dot} />
            ))}
          </div>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={1}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item xs={12} md={2}>
        <FormControl margin="normal" fullWidth>
          <Typography gutterBottom className={classes.label}>
            Role
          </Typography>

          <Typography className={classes.textTransformCapitalize} variant="button">{role.value}</Typography>
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <Typography gutterBottom className={classes.label}>
            ID
          </Typography>

          <Typography variant="button">{id}</Typography>
        </FormControl>
      </Grid>
      <Grid item style={{ maxWidth: '2%', flexBasis: '2%' }}>
        <Divider orientation="vertical" />
      </Grid>

      <Grid item xs={12} md={1}>
        <FormControl margin="normal" fullWidth>
          <Typography gutterBottom>
            {
              (customer?.is_show_billing === true)
                ? (<BillingComponent />)
                : (<></>)
            }
          </Typography>
        </FormControl>
      </Grid>

    </>
  );
};
