import React, { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import { makeStyles, Paper } from '@material-ui/core';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import DeleteOutline from 'src/assets/icons/close.svg';
import Delete from 'src/assets/icons/delete.svg';
import Edit from 'src/assets/icons/edit.svg';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Lato',
    '& .MuiTableCell-root': {
      fontFamily: 'Lato',
    },
    '& tr:last-child > td': {
      border: 'unset',
    },
  },
}));

export default function Table({ components, options, ...props }) {
  const classes = useStyles();

  return (
    <MaterialTable
      {...props}
      icons={tableIcons}
      components={{
        Container: containerProps => <Paper {...containerProps} elevation={0} className={classes.root} />,
        ...components,
      }}
      options={{
        search: false,
        showTitle: false,
        toolbar: false,
        paging: false,
        draggable: false,
        actionsColumnIndex: -1,
        ...options,
        headerStyle: {
          fontFamily: 'Lato',
          fontSize: 14,
          fontWeight: 700,
          border: 'unset',
          ...options.headerStyle,
        },
        rowStyle: {
          fontSize: 14,
          height: 74,
          whiteSpace: 'nowrap',
          fontWeight: 400,
          ...options.rowStyle,
        },
      }}
    />
  );
}
