/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector } from 'react-redux';

import { getMe } from 'src/features/users/state/selectors';

import { DetailsFormView } from './DetailsFormView';

export const DetailsView = () => {
  const data = useSelector(getMe);

  return data && (<DetailsFormView data={data} />);
};
